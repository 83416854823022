import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { style: "height: " + (_vm.FullHeight ? "80%" : "auto") }, [
    _c(
      "p",
      {
        staticClass: "dxa-text-field-label mb-1",
        style: "color: " + _vm.color,
        attrs: { id: "label" },
      },
      [_vm._v(" " + _vm._s(_vm.Field) + " ")]
    ),
    _c(
      "div",
      {
        style:
          "position: relative; height: " + (_vm.FullHeight ? "80%" : "auto"),
      },
      [
        _vm.IsCurrency
          ? _c("money", {
              staticClass: "dxa-text-field",
              style:
                "color: " +
                (_vm.color == "red"
                  ? "red"
                  : _vm.Disabled
                  ? "#9C9C9C"
                  : "var(--dark)"),
              attrs: {
                prefix: _vm.getCurrencyPrefixData(_vm.Currency),
                disabled: _vm.Disabled,
                thousand: ".",
                thousands: ".",
                decimal: ",",
                type: "text",
              },
              on: { focus: _vm.field_selected, blur: _vm.field_unselected },
              model: {
                value: _vm.text,
                callback: function ($$v) {
                  _vm.text = $$v
                },
                expression: "text",
              },
            })
          : _vm.IsDate
          ? _c("input", {
              directives: [
                {
                  name: "mask",
                  rawName: "v-mask",
                  value: "##/##/####",
                  expression: "'##/##/####'",
                },
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.text,
                  expression: "text",
                },
              ],
              staticClass: "dxa-text-field",
              style:
                "color: " +
                (_vm.color == "red"
                  ? "red"
                  : _vm.Disabled
                  ? "#9C9C9C"
                  : "var(--dark)"),
              attrs: {
                disabled: _vm.Disabled,
                placeholder: "DD/MM/YYYY",
                type: "text",
              },
              domProps: { value: _vm.text },
              on: {
                focus: _vm.field_selected,
                blur: _vm.field_unselected,
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.text = $event.target.value
                },
              },
            })
          : _vm.FullHeight
          ? _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.text,
                  expression: "text",
                },
              ],
              staticClass: "dxa-textarea dxa-text-field",
              style:
                "color: " +
                (_vm.color == "red"
                  ? "red"
                  : _vm.Disabled
                  ? "#9C9C9C"
                  : "var(--dark)"),
              attrs: { disabled: _vm.Disabled, type: "text", rows: "5" },
              domProps: { value: _vm.text },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.text = $event.target.value
                },
              },
            })
          : _vm.Mask
          ? (_vm.Type ? _vm.Type : "text") === "checkbox"
            ? _c("input", {
                directives: [
                  {
                    name: "mask",
                    rawName: "v-mask",
                    value: _vm.Mask,
                    expression: "Mask",
                  },
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.text,
                    expression: "text",
                  },
                ],
                staticClass: "dxa-text-field",
                style:
                  "color: " +
                  (_vm.color == "red"
                    ? "red"
                    : _vm.Disabled
                    ? "#9C9C9C"
                    : "var(--dark)"),
                attrs: { disabled: _vm.Disabled, type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.text)
                    ? _vm._i(_vm.text, null) > -1
                    : _vm.text,
                },
                on: {
                  focus: _vm.field_selected,
                  blur: _vm.field_unselected,
                  change: function ($event) {
                    var $$a = _vm.text,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.text = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.text = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.text = $$c
                    }
                  },
                },
              })
            : (_vm.Type ? _vm.Type : "text") === "radio"
            ? _c("input", {
                directives: [
                  {
                    name: "mask",
                    rawName: "v-mask",
                    value: _vm.Mask,
                    expression: "Mask",
                  },
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.text,
                    expression: "text",
                  },
                ],
                staticClass: "dxa-text-field",
                style:
                  "color: " +
                  (_vm.color == "red"
                    ? "red"
                    : _vm.Disabled
                    ? "#9C9C9C"
                    : "var(--dark)"),
                attrs: { disabled: _vm.Disabled, type: "radio" },
                domProps: { checked: _vm._q(_vm.text, null) },
                on: {
                  focus: _vm.field_selected,
                  blur: _vm.field_unselected,
                  change: function ($event) {
                    _vm.text = null
                  },
                },
              })
            : _c("input", {
                directives: [
                  {
                    name: "mask",
                    rawName: "v-mask",
                    value: _vm.Mask,
                    expression: "Mask",
                  },
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.text,
                    expression: "text",
                  },
                ],
                staticClass: "dxa-text-field",
                style:
                  "color: " +
                  (_vm.color == "red"
                    ? "red"
                    : _vm.Disabled
                    ? "#9C9C9C"
                    : "var(--dark)"),
                attrs: {
                  disabled: _vm.Disabled,
                  type: _vm.Type ? _vm.Type : "text",
                },
                domProps: { value: _vm.text },
                on: {
                  focus: _vm.field_selected,
                  blur: _vm.field_unselected,
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.text = $event.target.value
                  },
                },
              })
          : _vm.Type == "password"
          ? _c(
              "div",
              { staticClass: "input-with-btn" },
              [
                (_vm.showPassword ? "text" : "password") === "checkbox"
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.text,
                          expression: "text",
                        },
                      ],
                      staticClass: "dxa-text-field",
                      style:
                        "color: " +
                        (_vm.color == "red"
                          ? "red"
                          : _vm.Disabled
                          ? "#9C9C9C"
                          : "var(--dark)"),
                      attrs: { type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(_vm.text)
                          ? _vm._i(_vm.text, null) > -1
                          : _vm.text,
                      },
                      on: {
                        focus: _vm.field_selected,
                        blur: _vm.field_unselected,
                        change: function ($event) {
                          var $$a = _vm.text,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.text = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.text = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.text = $$c
                          }
                        },
                      },
                    })
                  : (_vm.showPassword ? "text" : "password") === "radio"
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.text,
                          expression: "text",
                        },
                      ],
                      staticClass: "dxa-text-field",
                      style:
                        "color: " +
                        (_vm.color == "red"
                          ? "red"
                          : _vm.Disabled
                          ? "#9C9C9C"
                          : "var(--dark)"),
                      attrs: { type: "radio" },
                      domProps: { checked: _vm._q(_vm.text, null) },
                      on: {
                        focus: _vm.field_selected,
                        blur: _vm.field_unselected,
                        change: function ($event) {
                          _vm.text = null
                        },
                      },
                    })
                  : _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.text,
                          expression: "text",
                        },
                      ],
                      staticClass: "dxa-text-field",
                      style:
                        "color: " +
                        (_vm.color == "red"
                          ? "red"
                          : _vm.Disabled
                          ? "#9C9C9C"
                          : "var(--dark)"),
                      attrs: { type: _vm.showPassword ? "text" : "password" },
                      domProps: { value: _vm.text },
                      on: {
                        focus: _vm.field_selected,
                        blur: _vm.field_unselected,
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.text = $event.target.value
                        },
                      },
                    }),
                _c(
                  VIcon,
                  {
                    attrs: { size: "20", color: "grey" },
                    on: {
                      click: function ($event) {
                        _vm.showPassword = !_vm.showPassword
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.showPassword ? "mdi-eye" : "mdi-eye-off"))]
                ),
              ],
              1
            )
          : (_vm.Type ? _vm.Type : "text") === "checkbox"
          ? _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.text,
                  expression: "text",
                },
              ],
              staticClass: "dxa-text-field",
              style:
                "color: " +
                (_vm.color == "red"
                  ? "red"
                  : _vm.Disabled
                  ? "#9C9C9C"
                  : "var(--dark)"),
              attrs: {
                disabled: _vm.Disabled,
                min: _vm.Min,
                max: _vm.Max,
                type: "checkbox",
              },
              domProps: {
                checked: Array.isArray(_vm.text)
                  ? _vm._i(_vm.text, null) > -1
                  : _vm.text,
              },
              on: {
                focus: _vm.field_selected,
                blur: _vm.field_unselected,
                change: function ($event) {
                  var $$a = _vm.text,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.text = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.text = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.text = $$c
                  }
                },
              },
            })
          : (_vm.Type ? _vm.Type : "text") === "radio"
          ? _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.text,
                  expression: "text",
                },
              ],
              staticClass: "dxa-text-field",
              style:
                "color: " +
                (_vm.color == "red"
                  ? "red"
                  : _vm.Disabled
                  ? "#9C9C9C"
                  : "var(--dark)"),
              attrs: {
                disabled: _vm.Disabled,
                min: _vm.Min,
                max: _vm.Max,
                type: "radio",
              },
              domProps: { checked: _vm._q(_vm.text, null) },
              on: {
                focus: _vm.field_selected,
                blur: _vm.field_unselected,
                change: function ($event) {
                  _vm.text = null
                },
              },
            })
          : _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.text,
                  expression: "text",
                },
              ],
              staticClass: "dxa-text-field",
              style:
                "color: " +
                (_vm.color == "red"
                  ? "red"
                  : _vm.Disabled
                  ? "#9C9C9C"
                  : "var(--dark)"),
              attrs: {
                disabled: _vm.Disabled,
                min: _vm.Min,
                max: _vm.Max,
                type: _vm.Type ? _vm.Type : "text",
              },
              domProps: { value: _vm.text },
              on: {
                focus: _vm.field_selected,
                blur: _vm.field_unselected,
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.text = $event.target.value
                },
              },
            }),
        _vm.ErrorText && !_vm.typing
          ? _c("span", { staticClass: "error-msg" }, [
              _vm._v(_vm._s(_vm.$t(_vm.ErrorText))),
            ])
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }